import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, IconButton, Stack, Typography,Card, CardContent, CardActions, Divider, Box, Container } from '@mui/material';
import DataBaseService from '../services/dataBaseService';
import PrintIcon from '@mui/icons-material/Print';
import { useState } from 'react';

const dataBaseService = new DataBaseService();

const isMobile = window.innerWidth > 500 ? false : true

const Row = (props) => {
    const {data, index} = props;

    

    
    return (
        <>
        <TableRow
            key={data.num}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
            <TableCell component="th" scope="row">
                {data.num}
            </TableCell>
            <TableCell align="right">{data.postnum}</TableCell>
            <TableCell align="right">{data.cash}</TableCell>
            <TableCell align="right">{data.term}</TableCell>
            <TableCell align="right">{data.coins}</TableCell>
            <TableCell align="right">{data.checkdata}</TableCell>
            <TableCell align="right"><Button variant='contained' onClick={() => props.print(index)}>Распечатать</Button></TableCell>
        </TableRow>
        
        </>
    )
}

export default function BillStatistics(props) {
    const [data, setData] = React.useState()
    const [index, setIndex] = React.useState(0)
    const [offset, setOffset] = React.useState(0)
    const [isScrolledToBottom, setIsScrolledToBottom] = React.useState(false);
    const [stopScroll, setStopScroll] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const handleScroll = () => {
        if (!stopScroll) {
          const elementHeight = document.body.scrollHeight;
          const windowHeight = window.innerHeight;
          const scrolledHeight = window.scrollY;
          const isScrolledToBottom = scrolledHeight >= elementHeight - windowHeight - 1;
    
          setIsScrolledToBottom(isScrolledToBottom);
        }
    };

    function throttle(callee, timeout) {
        let timer = null
        
        return function perform(...args) {
            if (timer) return
        
            timer = setTimeout(() => {
            callee(...args)
        
            clearTimeout(timer)
            timer = null
            }, timeout)
        }
    }    

    const fetchData = (newOffset) => {
        setLoading(true);
        dataBaseService
          .getResource(`https://atod.online/statistics.php?wht=check&cw=${props.wash}&with=${newOffset}&kol=20`)
          .then((response) => response.json())
          .then((newData) => {
            if (newData.length < 1) {
              setStopScroll(true);
            } else {
              setData((prevRows) => prevRows ? [...prevRows, ...newData] : [...newData]);
              console.log(data)
              setOffset(newOffset);
            }
            setLoading(false);
          });
    };

    React.useEffect(() => {
        if (props.wash !== undefined) {
          setOffset(1);
          setStopScroll(false);
          setIsScrolledToBottom(false);
          setData();
    
          fetchData(0);
        }
    }, [props.wash]);
    
    React.useEffect(() => {
        if (!stopScroll) {
          window.addEventListener('scroll', throttle(handleScroll, 100));
        }
    
        return () => {
          
            window.removeEventListener('scroll', throttle);
          
        };
    }, [props.wash, stopScroll]);
    
    React.useEffect(() => {
        if (isScrolledToBottom && !stopScroll) {
          fetchData(offset + 20);
        }
    }, [isScrolledToBottom, stopScroll]);  
    
    // React.useEffect(()=>{
    //     dataBaseService.getResource(`https://atod.online/statistics.php?wht=check&cw=27&with=${offset}&kol=15`)
    //     .then(response => response.json())
    //     .then(data => {
    //         console.log(data)
    //         setData(data)
    //     })
    // },[])

    const  print = async (index) => {
        await setIndex(()=> index)
        window.print()
    }

    if (isMobile) return (
        <>
        {data && <Box  sx={{display: 'none', '@media print': {display: 'grid', position:'absolute', left:'0', top:'0', width:'100vw'}}}>
            <Container maxWidth={'xs'}>
            <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography style={{textAlign: 'left',  marginLeft:'4px'}}> Пост: {data[index].postnum}</Typography>
            <Typography style={{textAlign: 'right', marginRight:'4px'}}> №{data[index].num}</Typography>
            </Stack>
            <Divider></Divider>
            <Typography style={{textAlign: 'center', marginTop: '4px'}}> Сумма: {+data[index].cash + +data[index].coins + +data[index].term}</Typography>
            <Typography style={{textAlign: 'center', marginTop: '4px'}}> Наличные: {data[index].cash}</Typography>
            <Typography style={{textAlign: 'center', marginTop: '4px'}}> Монеты: {data[index].coins}</Typography>
            <Typography style={{textAlign: 'center', marginTop: '4px'}}> Терминал: {data[index].term}</Typography>
            </Container>
        </Box>}
        {data ? data.map((data, index) => (
            <Card raised sx={{ width:'96vw', ml:'2vw', mt:1,  borderRadius:'12px', background: 'rgba(255,255,255,0.1)', '@media print': {
                display: 'none'
              } }}>
                <CardContent sx={{pb:0}}>
                    <Typography color="text.secondary" width={'100%'} fontSize={'18px'} sx={{mb:-2, textAlign:'center'}}>{data.checkdata}</Typography>
                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'end'}>
                        <Stack>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                Пост {data.postnum}
                            </Typography>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                № {data.num}
                            </Typography>
                        </Stack>
                        
                        
                        <IconButton onClick={() => print(index)} children={<PrintIcon/> }/>
                    </Stack>
                    
                    <Divider sx={{mt:1}}/>
                    <Stack direction={'row'} justifyContent={'space-between'} >
                    <Typography component="div" color="text.secondary" fontSize={'16px'}>
                    Купюры 
                        <Typography fontSize={'14px'}>{data.cash}₽</Typography>
                    </Typography>
                    <Typography component="div" color="text.secondary" fontSize={'16px'}>
                    Монеты
                    <Typography fontSize={'14px'}>{data.coins}₽</Typography>
                    </Typography>
                    <Typography component="div" color="text.secondary" fontSize={'16px'}>
                    Терминал
                    <Typography fontSize={'14px'}>{data.term}₽</Typography>
                    </Typography>
                    </Stack>
                </CardContent>
            
            </Card>
        )) : null}
        </>
    );
    
    return (
        <>
        {data && <Box  sx={{display: 'none', '@media print': {display: 'grid', position:'absolute', left:'0', top:'0', width:'100vw'}}}>
            <Container maxWidth={'xs'}>
            <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography style={{textAlign: 'left',  marginLeft:'4px'}}> Пост: {data[index].postnum}</Typography>
            <Typography style={{textAlign: 'right', marginRight:'4px'}}> №{data[index].num}</Typography>
            </Stack>
            <Divider></Divider>
            <Typography style={{textAlign: 'center', marginTop: '4px'}}> Сумма: {+data[index].cash + +data[index].coins + +data[index].term}</Typography>
            <Typography style={{textAlign: 'center', marginTop: '4px'}}> Наличные: {data[index].cash}</Typography>
            <Typography style={{textAlign: 'center', marginTop: '4px'}}> Монеты: {data[index].coins}</Typography>
            <Typography style={{textAlign: 'center', marginTop: '4px'}}> Терминал: {data[index].term}</Typography>
            </Container>
        </Box>}
        
        <TableContainer component={Paper} sx={{mt:2, ml:1, width:'96%', background:'rgba(255,255,255,0.1)', '@media print': {
            display: 'none'
          }}}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
            <TableRow>
                <TableCell>№</TableCell>
                <TableCell align="right">Пост</TableCell>
                <TableCell align="right">Купюры</TableCell>
                <TableCell align="right">Терминал</TableCell>
                <TableCell align="right">Монеты</TableCell>
                <TableCell align="right">Дата</TableCell>
                <TableCell align="right"><Button variant='contained' onClick={() => window.print()}>Распечатать все</Button></TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {data && data.map((data, index) => (
                <Row data={data} index={index} print={() => print(index)}/>
                // <TableRow
                // key={data.num}
                // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                // >
                // <TableCell component="th" scope="row">
                //     {data.num}
                // </TableCell>
                // <TableCell align="right">{data.postnum}</TableCell>
                // <TableCell align="right">{data.cash}</TableCell>
                // <TableCell align="right">{data.term}</TableCell>
                // <TableCell align="right">{data.coins}</TableCell>
                // <TableCell align="right">{data.checkdata}</TableCell>
                // <TableCell align="right"><Button variant='contained'>Распечатать</Button></TableCell>
                // </TableRow>
            ))}
            </TableBody>
        </Table>
        </TableContainer>
        </>
    );
}
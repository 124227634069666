import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControlLabel, Switch, Snackbar, Alert } from '@mui/material';

export default function BlockingCard(props) {
  const [open, setOpen] = React.useState(false);
  const [openAllert, setOpenAllert] = React.useState(false)
  const [checked, setChecked] = React.useState(props.status == '0'? false : true);

  const handleClickOpen = () => {
    if (props.isActive !== '1') {
      setOpen(true);
    } else setOpenAllert(true)
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseAllert = () => {
    setOpenAllert(false)
  }

  const confirm = () => {
    fetch (`https://atod.online/cards.php`, {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        },
        body: JSON.stringify({wsh:+props.wash, blkUsr: checked ? 0 : 1, cud:+props.cardUid})
    })
    setChecked(!checked)
    handleClose()
  }

  return (
    <>
      <FormControlLabel control={<Switch size='small' checked={checked} color={checked ? 'secondary':'primary'} sx={{mr:'-10px', ml:'15px'}} onClick={handleClickOpen}/>}  />
      <Snackbar open={openAllert} autoHideDuration={6000} onClose={handleCloseAllert}>
        <Alert onClose={handleCloseAllert} severity="warning" >
          Изменение статуса не возможно пока карта активна!
        </Alert>
      </Snackbar>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Статус карты"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {checked == false ? 'Заблокировать карту?' : 'Разблокировать карту?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Отмена</Button>
          <Button onClick={confirm} autoFocus>
          {checked == false ? 'Заблокировать' : 'Разблокировать'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
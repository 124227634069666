import React from 'react';
import dayjs from 'dayjs';
import ModeEditRoundedIcon from '@mui/icons-material/ModeEditRounded';
import { IconButton, Modal, Backdrop, Fade, Box, Typography, Grid, TextField, MenuItem, Button } from '@mui/material';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers';
import 'dayjs/locale/ru';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 340,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};



const EditPost = (props) => {
    const [open, setOpen] = React.useState(false);
    const [title, setTitle] = React.useState('Пожалуйста заполните данные');
    const [isAdded, setIsAdded] = React.useState(false);
    const [postNum, setPostNum] = React.useState('');
    const [postType, setPostType] = React.useState('');
    const [warrantyDate, setWarrantyDate] = React.useState(dayjs());
    const postNums = props.postItems.map(item => item.postNum);
    const handleOpen = () => setOpen(true);
    
    const handleClose = () => {
        setOpen(false);
        setTimeout(setIsAdded, 1000, (false));
        setTimeout(setTitle, 1000, ('Пожалуйста заполните данные'));
    };

    const typesList = [
        'Business',
        'GlossV1_24',
        'GlossV2',
        'GlossV3',
        'Pilesos'
    ]

    return (
        <>
            <IconButton color="secondary" onClick={handleOpen}  children={<ModeEditRoundedIcon fontSize="large"/>}></IconButton>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                backdrop: {
                    timeout: 500,
                },
                }}
            >
                <Fade in={open}>
                <Box sx={style}>
                    <Typography id="transition-modal-title" variant="h6" component="h2" textAlign={'center'}>
                    {title}
                    </Typography>
                <Grid container>
                    <Grid item xs={12}>            
                        <TextField
                            id="type"
                            margin='normal'
                            select
                            label="Type"
                            defaultValue={postType}
                            helperText="Тип поста"
                            onChange={(e) => setPostType(e.target.value)}
                            >
                            {typesList.map((item) => (
                                <MenuItem key={item} value={item}>
                                {item}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>             
                    <Grid item xs={12}> 
                        <TextField
                            id="PostNum"
                            label="PostNum"
                            type='number'
                            margin='normal'
                            helperText='номер поста'
                            value={postNum}
                            onChange={(event) => {
                            setPostNum(event.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>  
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
                            <DatePicker
                                label="Дата гарантии"
                                format="DD.MM.YYYY"
                                value={warrantyDate}
                                onChange={(newValue) => setWarrantyDate(newValue)}
                            />
                        </LocalizationProvider>
                    </Grid>     
                    </Grid>
                    {!isAdded ? <Grid container justifyContent={'space-around'} mt={2} >
                        <Button color='error' onClick={handleClose}>Отмена</Button>
                        <Button color='success'  >Добавить пост</Button>
                    </Grid> : null}
                </Box>
                </Fade>
            </Modal>
        </>
    )
}

export default EditPost;
import TodayStatistic from "../TodayStatistic";
import PostInfo from "../PostsInfo";
import { Divider, Grid, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useState, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import DataBaseService from "../../services/dataBaseService";
import AddPost from "../AddPost";
import AddWash from "../AddWash";

const MainPage = () => {
    const [postItems, setPostItems] = useState([]);
    const [isAuth, setIsAuth] = useState(true);
    const [washesList, setWashesList] = useState();
    const [visibleWash, setVisibleWash] = useState();
    const [visiblePostName, setVisiblePostName] = useState('all');
    const [timerId, setTimerId] = useState(null);
    const [isNewWashAdded, setIsNewWashAdded] = useState(false);
    
    const location = useLocation();
    const dataBaseService = new DataBaseService();
    const washNumSession = sessionStorage.getItem('wash');
    const visiblePost = visiblePostName === 'all' ? postItems : [postItems.find(item => item.postNum === visiblePostName)]

    useEffect (() => {
        visibleWash && dataBaseService.getPostData(visibleWash, setIsAuth, setPostItems);       // При изменении отображаемой мойки получаем от сервера новый список постов
    },[visibleWash])

    useEffect (() => {
        dataBaseService.getWashesList(setIsAuth)      // При изменении состояния отвечающего за добавление новой мойки обновляем список моек
        .then(data => setWashesList(data))
    },[isNewWashAdded])

    useEffect (() => {
        if (!sessionStorage.getItem('wash') && washesList && washesList.length > 0) {
            setVisibleWash(washesList[0].id);
            // sessionStorage.setItem('wash', washesList[0].id )
           } else {
            setVisibleWash(sessionStorage.getItem('wash'))
           }
            // При изменении списка моек устанавливаем видимой первую мойку в списке
    }, [washesList])

    useEffect(() => {
        clearInterval(timerId)
        const interval = visibleWash &&  setInterval(dataBaseService.getPostData,5000, visibleWash, setIsAuth, setPostItems)  ;  // При изменении отображаемой мойки очищаем старую подписку, устанавливем новую
        setTimerId(interval)
        return () => {clearInterval(timerId)};
        
    },[visibleWash])

    useEffect(() => {
        return () => {
            clearInterval(timerId) // При изменении локации удаляем подписку
        }
    },[location, timerId])

    const handleChangePost = (event) => {
        setVisiblePostName(event.target.value);
      };

    const handleChangeWash = (event) => {
    sessionStorage.setItem('wash', event.target.value)
    console.log(washesList)
    sessionStorage.setItem('washName', JSON.stringify(washesList.filter(item => item.id == event.target.value)))
    setVisibleWash(event.target.value);
    setVisiblePostName('all')
    }; 
    
    const refreshWashList = () => {
        setIsNewWashAdded(!isNewWashAdded)  // При добавлении новой мойки заставляет обновляться список моек
    }

    
    
    return (
        <>
        {!isAuth ? <Navigate to={'/login'}/> : null}
        <TodayStatistic postItems={postItems} wash={visibleWash}/>
        <Divider sx={{mt: 5, mb: 5}}/>

         <Grid container spacing={2} pl={1}>
            {(washesList && visibleWash) && <Grid item>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel id="demo-select-small-label">Мойка</InputLabel>
                    <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={visibleWash}
                        label='Мойка'
                        onChange={handleChangeWash}
                    >
                        <MenuItem value={washesList[0].id}>
                        {washesList[0].street}
                        </MenuItem>
                        {
                            washesList.map((item, num) => {
                                if (num === 0 ) {
                                    return null
                                }
                                return <MenuItem key={item.id} value={item.id}>{`${item.street} ${item.building}`}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
            </Grid>}
            {(visiblePostName && postItems) && <Grid item>
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel id="demo-select-small-label">Пост</InputLabel>
                    <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={visiblePostName}
                        label="Пост"
                        onChange={handleChangePost}
                    >
                        <MenuItem value="all">
                        Все
                        </MenuItem>
                        {
                            postItems.map(({postNum}) => {
                                return <MenuItem key={postNum} value={postNum}>{postNum}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
            </Grid>}
            
            <Grid item alignSelf={'center'}>
                {/* <Button variant="contained">Добавить новый пост</Button> */}
                {(washesList && visibleWash) && <AddPost washesList={washesList} visibleWash={visibleWash} postItems={postItems}/>}
            </Grid>
            <Grid item alignSelf={'center'}>
                {/* <Button variant="contained">Добавить новый пост</Button> */}
                <AddWash refreshWashList={refreshWashList} />
            </Grid>
        </Grid>

        <PostInfo postItems={visiblePost}/>
        </>
    )
}

export default MainPage;
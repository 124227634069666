import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid, TextField, MenuItem } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/ru';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 340,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};



export default function AddPost(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setTimeout(setIsAdded, 1000, (false));
    setTimeout(setTitle, 1000, ('Пожалуйста заполните данные'));
  };
  const [title, setTitle] = React.useState('Пожалуйста заполните данные');
  const [isAdded, setIsAdded] = React.useState(false);
  const [postNum, setPostNum] = React.useState('');
  const [washNum, setWashNum] = React.useState(props.visibleWash);
  const [postType, setPostType] = React.useState('');
  const [warrantyDate, setWarrantyDate] = React.useState(dayjs());
  const postNums = props.postItems.map(item => item.postNum);
   

  React.useEffect(() => {
    setWashNum(props.visibleWash)
  }, [props.visibleWash])

  const typesList = [
    'Business',
    'GlossV1_24',
    'GlossV2',
    'GlossV3',
    'Pilesos'
  ]

    const addNewPost = () => {
        if (postNum && washNum && postType && warrantyDate && postNum > 0) {
          if(postNums.includes(postNum)) {
            setTitle('Пост с указанным номером уже существует')
          } else {
            fetch('https://atod.online/data.php', {
                method: "POST",
                headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                },
                body: JSON.stringify({washID: washNum, postNum: postNum, postType: postType, warrantyDate: warrantyDate.format('YYYY-MM-DD')})
                })
                .then(response => {
                    if (response.ok) {
                        console.log(response);
                        setIsAdded(true);
                        setTitle('Пост успешно добавлен!');
                        setTimeout(handleClose, 3000)
                    } else if (response.status === 401) {
                        localStorage.removeItem("jwt");
                        console.log(response)
                        throw new Error(`Ошибка ${response.status} : ${response.statusText}`)
                    } else {
                        console.log(response)
                        throw new Error(`Ошибка ${response.status} : ${response.statusText}`)
                    }
                })
                .catch(error => setTitle(`${error.name}: ${error.message}`))
                }
              
        } else if (postNum < 1 ) {
          setTitle('Указан не верный номер поста ')
        } else {
          setTitle('Заполнены не все данные')
        }
        
        
        
    }

  return (
    <div>
      <Button onClick={handleOpen} variant='contained' color='secondary'>Добавить новый пост</Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2" textAlign={'center'}>
              {title}
            </Typography>
        <Grid container>
            {(props.washesList ) && <Grid item xs={12}>   
                <TextField
                    id="wash"
                    margin='normal'
                    select
                    label="Wash"
                    defaultValue={washNum}
                    helperText="Номер мойки"
                    onChange={(e) => setWashNum(e.target.value)}
                    >
                    {props.washesList.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                        {`${item.street} ${item.building}`}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>}
            <Grid item xs={12}>            
                <TextField
                    id="type"
                    margin='normal'
                    select
                    label="Type"
                    defaultValue={postType}
                    helperText="Тип поста"
                    onChange={(e) => setPostType(e.target.value)}
                    >
                    {typesList.map((item) => (
                        <MenuItem key={item} value={item}>
                        {item}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>             
            <Grid item xs={12}> 
                <TextField
                    id="PostNum"
                    label="PostNum"
                    type='number'
                    margin='normal'
                    helperText='номер поста'
                    value={postNum}
                    onChange={(event) => {
                    setPostNum(event.target.value);
                    }}
                />
            </Grid>
            <Grid item xs={12}>  
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
                    <DatePicker
                        label="Дата гарантии"
                        format="DD.MM.YYYY"
                        value={warrantyDate}
                        onChange={(newValue) => setWarrantyDate(newValue)}
                    />
                </LocalizationProvider>
            </Grid>     
            </Grid>
            {!isAdded ? <Grid container justifyContent={'space-around'} mt={2} >
                <Button color='error' onClick={handleClose}>Отмена</Button>
                <Button color='success' onClick={() => addNewPost()} >Добавить пост</Button>
            </Grid> : null}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}


import {Card, Grid, CardContent, Typography, Box, ToggleButton, ToggleButtonGroup, Button, Container, useTheme} from "@mui/material"
import { useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import AverageBill from "./AverageBill";
import Cars from "./Cars";
import 'dayjs/locale/ru';



const TodayStatistic = (props) => {
    const [moreButtonTitle, setMoreButtonTitle] = useState('больше информации');
    const [displayInfo, setDisplayInfo] = useState('none');
    const [startDay, setStartDay] = useState(() => dayjs());
    const [endDay, setEndDay] = useState(() => dayjs());
    const [cashMoney, setCashMoney] = useState('');
    const [cashlessMoney, setCashlessMoney] = useState('');

    const theme = useTheme();

    const {postItems} = props;

    useEffect(() => {
        setCashMoney(postItems.reduce((accumulator, currentValue) => accumulator + +currentValue.currMonBal + +currentValue.currCoinsBal, 0));
        setCashlessMoney(postItems.reduce((accumulator, currentValue) => accumulator + +currentValue.currTermBal, 0))
    }, [postItems])

    // console.log(currentDay.format('YYYY-MM-DD'));
    
   

    const changeVisability = () => {
        setMoreButtonTitle(() => {
           return moreButtonTitle === 'больше информации' ? 'меньше информации' : 'больше информации'
        })

        setDisplayInfo(() => {
            return displayInfo === 'none' ? 'flex' : 'none'
        })
    }
    
    return (
        <>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
            <Grid container spacing={2} mb={2}  justifyContent={'start'} sx={{pt:2}}>
                <Grid item ml={2} xs={5} md={2} >   
                    <DatePicker
                        format="DD.MM.YYYY"
                        label="Начало периода"
                        value={startDay}
                        maxDate={endDay}
                        slotProps={{ textField: { size: 'small' } }}
                        onChange={(newValue) => setStartDay(newValue)}
                    />
                </Grid> 
                <Grid item ml={2} xs={5} md={2}>
                    <DatePicker
                        format="DD.MM.YYYY"
                        label="Конец периода"
                        value={endDay}
                        maxDate={dayjs()}
                        slotProps={{ textField: { size: 'small' } }}
                        onChange={(newValue) => setEndDay(newValue)}
                    />
                </Grid>
                <Grid item alignSelf={'center'}><Typography variant="span" >{(startDay > endDay) ? 'не правильный период' : null}</Typography></Grid>
            </Grid>
        </LocalizationProvider>




        <Grid container spacing={2} >
            <Grid item ml={2} >
                <Card raised sx={{ minWidth: 320, borderRadius: 3, height: '100%', background: theme.palette.background.opacityCard}}>
                    <CardContent >
                        <Typography sx={{ fontSize: 14, fontWeight: 'bold' }} color="text.secondary" gutterBottom>
                        Текущая сумма (купюры и монеты)
                        </Typography>
                        <Typography sx={{fontSize: 24, fontWeight: 'bold'}} color='text.secondary' >
                         ₽ {cashMoney} 
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>

            
            <Grid item ml={2}  display={{xs: displayInfo, md:'flex'}}>
                <Card raised sx={{ width: 320, borderRadius: 3, height: '100%', background: theme.palette.background.opacityCard }}>
                    <CardContent>
                        <Typography sx={{ fontSize: 14, fontWeight: 'bold' }} color="text.secondary" gutterBottom>
                        Текущая сумма (безнал)
                        </Typography>
                        <Typography sx={{fontSize: 24, fontWeight: 'bold'}}  color='text.secondary' >
                         ₽ {cashlessMoney} 
                        </Typography>
                        
                    </CardContent>
                </Card>
            </Grid>

            <Grid item ml={2}  display={{xs: displayInfo, md:'flex'}}>
                <Card raised sx={{ width: 320, borderRadius: 3, height: '100%', background:theme.palette.background.opacityCard }}>
                    <AverageBill startDay={startDay} endDay={endDay} wash={props.wash}/>
                    {/* <CardContent>
                        <Grid container justifyContent={'space-between'}>
                            <Grid item>
                            <Typography sx={{ fontSize: 14, fontWeight: 'bold' }} color="text.secondary" gutterBottom>
                            Средний 'чек'
                            </Typography>
                            </Grid>
                        </Grid>
                        <Box bgcolor={'secondary.main'} width={'80%'} ml={'-15px'} borderRadius={'0 20px 20px 0'} >
                        <Typography sx={{fontSize: 24, fontWeight: 'bold'}} ml={'15px'} color='background.default' >
                         ₽ 320.00 
                        </Typography>
                        </Box>
                    </CardContent> */}
                </Card>
            </Grid>

            <Grid item ml={2}  display={{xs: displayInfo, md:'flex'}}>
                <Card raised sx={{ width: 320, borderRadius: 3, height: '100%', background:theme.palette.background.opacityCard }}>
                    <Cars startDay={startDay} endDay={endDay} wash={props.wash}/>
                    {/* <CardContent>
                        <Typography sx={{ fontSize: 14, fontWeight: 'bold' }} color="text.secondary" gutterBottom>
                        Колличество машин
                        </Typography>
                        <Box bgcolor={'secondary.main'} width={'80%'} ml={'-15px'} borderRadius={'0 20px 20px 0'} >
                        <Typography sx={{fontSize: 24, fontWeight: 'bold'}} ml={'15px'} color='background.default' >
                         159 
                        </Typography>
                        </Box>
                    </CardContent> */}
                </Card>
            </Grid>

            <Grid item  xs={12} justifyContent={'center'} display={{xs:'flex', md:'none'}}>
                <Button size="small" onClick={changeVisability}>{moreButtonTitle}</Button>
            </Grid>
            
        </Grid>
        </>
    )
}
export default TodayStatistic;
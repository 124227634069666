import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DataBaseService from '../services/dataBaseService';
import {Divider, Grid} from '@mui/material';

import PersonSearchRoundedIcon from '@mui/icons-material/PersonSearchRounded';
import { IconButton } from '@mui/material';



export default function EditCardInfo(props) {
  const {cardUid} = props 
  const [info, setInfo] = React.useState({});  
  const [open, setOpen] = React.useState(false);
  const [name, setName] =React.useState();
  const [lastName, setLastName] = React.useState();
  const [surname, setSurname] = React.useState();
  const [phone, setPhone] = React.useState();
  const [carBrand, setCarBrand] = React.useState();
  const [carColor, setCarColor] = React.useState();
  const [carNum, setCarNum] = React.useState();
  const [personal, setPersonal] = React.useState();
  const [firma, setFirma] = React.useState();
  const [modalTitle, setModalTitle] = React.useState('Данные клиента')

 

  const handleClickOpen = () => {
    fetch(`https://atod.online/cards.php?wht=clinf&cud=${cardUid}&whs=${props.wash}`,{
      method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('jwt')}`,
          },
    } )
    .then(response => response.json())
    .then(info => {
      console.log(info)
      const {name, lastname, surname, phonenumber, carbrand, carcolor, carnumber, personaldiscont, firmdiscont} = info[0];
      setName(name);
      setLastName(lastname);
      setSurname(surname);
      setPhone(phonenumber);
      setCarBrand(carbrand);
      setCarColor(carcolor);
      setCarNum(carnumber);
      setPersonal(personaldiscont);
      setFirma(firmdiscont);
      setOpen(true);
    })
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(setModalTitle, 2000, 'Данные клиента')
  };

  const changeInfo = () => {
    if (name && lastName && surname && phone && carBrand && carColor && carNum) {
        fetch('https://atod.online/cards.php', {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem('jwt')}`,
            },
            body: JSON.stringify({
                wsh: props.wash,
                cud: cardUid,
                phne: phone,
                cusn: name,
                lcusn: lastName,
                scusn: surname,
                cbn: carBrand,
                cc: carColor,
                cn: carNum,
                pdis: +personal,
                fdis: +firma
            })
        })
        .then(response => {
            if (response.ok) {
                console.log(response);
                setModalTitle('Изменения произведены. Информация обновится после перезакрузки страницы.');
                setTimeout(handleClose, 3000);
            } else if (response.status === 401) {
                localStorage.removeItem("jwt");
                console.log(response)
                throw new Error(`Ошибка ${response.status} : ${response.statusText}`)
            } else {
                console.log(response)
                throw new Error(`Ошибка ${response.status} : ${response.statusText}`)
            }
          })
          .catch(error => setModalTitle(`${error.name}: ${error.message}`))
        
    }
  }

  return (
    <div>
      <IconButton  onClick={handleClickOpen} sx={{mt:'-5px', ml:'2px', color:'secondary.main'}} size='small'  children={<PersonSearchRoundedIcon fontSize='14px'/>}/>
     
      <Dialog open={open} onClose={handleClose} >
        <DialogTitle>Обновить информацию</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {modalTitle}
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item>
              <TextField
                margin="dense"
                id="name"
                label="Имя"
                defaultValue={name}
                type="text"
                variant="outlined"
                sx={{width:'160px'}}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item>
              <TextField
                margin="dense"
                id="surname"
                label="Отчество"
                defaultValue={surname}
                type="text"
                variant="outlined"
                sx={{width:'160px'}}
                onChange={(e) => setSurname(e.target.value)}
              />
            </Grid>
            <Grid item>
              <TextField
              margin="dense"
              id="lastname"
              label="Фамилия"
              defaultValue={lastName}
              type="text"
              variant="outlined"
              sx={{width:'160px'}}
              onChange={(e) => setLastName(e.target.value)}
              />
            </Grid>
          </Grid>
          <Divider sx={{mt:1, mb:1}}/>
          <Grid container>
            <Grid item>
              <TextField
              margin="dense"
              id="phone"
              label="Номер телефона"
              defaultValue={phone}
              type="tel"
              variant="outlined"
              onChange={(e) => setPhone(e.target.value)}
              />
            </Grid>
          </Grid>
          <Divider sx={{mt:1, mb:1}}/>
          <Grid container spacing={2}>
            <Grid item>
              <TextField
              autoFocus
              margin="dense"
              id="carBrand"
              label="Марка машины"
              defaultValue={carBrand}
              type="text"
              variant="outlined"
              onChange={(e) => setCarBrand(e.target.value)}
              />
            </Grid>
            <Grid item>
              <TextField
              autoFocus
              margin="dense"
              id="carColor"
              label="Цвет машины"
              defaultValue={carColor}
              type="text"
              variant="outlined"
              sx={{width:'120px'}}
              onChange={(e) => setCarColor(e.target.value)}
              />
            </Grid>
            <Grid item>
              <TextField
              autoFocus
              margin="dense"
              id="num"
              label="Гос. номер"
              defaultValue={carNum}
              type="text"
              variant="outlined"
              sx={{width:'120px'}}
              onChange={(e) => setCarNum(e.target.value)}
              />
            </Grid>
          </Grid>
          <Divider sx={{mt:1, mb:1}}/>
          <Grid container spacing={2}>
            <Grid item>
              <TextField
              autoFocus
              margin="dense"
              id="personal"
              label="Персональный дисконт"
              defaultValue={personal}
              type="text"
              variant="outlined"
              onChange={(e) => setPersonal(e.target.value)}
              />
            </Grid>
            <Grid item>
              <TextField
              autoFocus
              margin="dense"
              id="firma"
              label="Корпоративный дисконт"
              defaultValue={firma}
              type="text"
              variant="outlined"
              sx={{width:'120px'}}
              onChange={(e) => setFirma(e.target.value)}
              />
            </Grid>
          </Grid>
          
          
          
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Отмена</Button>
          <Button onClick={changeInfo}>Обновить</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
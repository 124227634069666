import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid, TextField} from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 340,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};



export default function AddWash(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setTimeout(setIsAdded, 1000, (false));
    setTimeout(setTitle, 1000, ('Пожалуйста заполните данные'));
  };
  const [title, setTitle] = React.useState('Пожалуйста заполните данные');
  const [isAdded, setIsAdded] = React.useState(false);
  const [country, setCountry] = React.useState('');
  const [city, setCity] = React.useState('');
  const [street, setStreet] = React.useState('');
  const [building, setBuilding] = React.useState('');

  const capitalLetter = (string) => {
    if (string && string.length > 0) {
      const newString = `${string[0].toUpperCase()}${string.slice(1)}`
       
      return newString
    } else {
      return string
    }
  }

 

  const addNewPost = () => {
      if (country && city && street && building ) {
        fetch('https://atod.online/acw.php', {
              method: "POST",
              headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem('jwt')}`,
              },
              body: JSON.stringify({country, city, street, building})
        })
        .then(response => {
            if (response.ok) {
                console.log(response);
                setIsAdded(true);
                setTitle('Мойка успешно добавлена!');
                setTimeout(handleClose, 3000);
                props.refreshWashList();
            } else if (response.status === 401) {
                localStorage.removeItem("jwt");
                console.log(response)
                throw new Error(`Ошибка ${response.status} : ${response.statusText}`)
            } else {
                console.log(response)
                throw new Error(`Ошибка ${response.status} : ${response.statusText}`)
            }
        })
        .catch(error => setTitle(`${error.name}: ${error.message}`))
      } else {
        setTitle('Заполнены не все данные')
      }
        
  }

  return (
    <div>
      <Button onClick={handleOpen} variant='contained' color='secondary' >Добавить новую мойку</Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2" textAlign={'center'}>
              {title}
            </Typography>

            <TextField
                id="country"
                margin='normal'
                label="Страна"
                value={country}
                onChange={(e) => e.target.value.length < 30 ? setCountry(capitalLetter(e.target.value.replace(/\d|\w|[!@#$%^&*()+="№;:?_/<>,.\\|~`'[\]]/gim,''))): null}
                >
            </TextField>

            <TextField
                id="city"
                margin='normal'
                label="Город"
                value={city}
                helperText="город"
                onChange={(e) => e.target.value.length < 30 ? setCity(capitalLetter(e.target.value.replace(/\d|\w|[!@#$%^&*()+="№;:?_/<>,.\\|~`'[\]]/gim,''))): null}
                >
            </TextField>

            <TextField
                id="street"
                margin='normal'
                label="Улица"
                value={street}
                helperText="улица"
                onChange={(e) => e.target.value.length < 30 ? setStreet(capitalLetter(e.target.value.replace(/[A-Za-z]|[!@#$%^&*()+="№;:?_/<>,.\\|~`'[\]]/gim,''))): null}
                >
            </TextField>

            <TextField
                id="building"
                margin='normal'
                label="Здание"
                value={building}
                helperText="номер здания"
                onChange={(e) => e.target.value.length < 30 ? setBuilding(e.target.value.replace(/[A-Za-z]|[!@#$%^&*+="№;:?_<>,.\\|~`'[\]]/gim,'')): null}
                >
            </TextField>
            
            {!isAdded ? <Grid container justifyContent={'space-around'} mt={2} >
                <Button color='error' onClick={handleClose}>Отмена</Button>
                <Button color='success' onClick={() => addNewPost()} >Добавить мойку</Button>
            </Grid> : null}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}


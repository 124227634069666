class DataBaseService {
   
  //Получение общих данных с проверкой токена
    getResource = async (url) => {
        let res = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('jwt')}`,
          },
        });
           
        return await res;
    }

    //Получение данных с мойки. setIsAuth - функия меняющая состояние авторизации, setData - устанавливает состояние с данными
    getPostData = (num, setIsAuth, setData) => {                                                 
      this.getResource(`https://atod.online/data.php?what=terminal&num=${num}`)
          .then(response => {
              if (response.ok) {
                  console.log(response)
                  return response.json()
                  
              } else if (response.status === 401) {
                  localStorage.removeItem("jwt");
                  setIsAuth(false);
                  console.log(response);
                  throw new Error(`Ошибка ${response.status} : ${response.statusText}`)
              } else {
                  console.log(response);
                  throw new Error(`Ошибка ${response.status} : ${response.statusText}`)
                  
              }
          })
          .then(data => {
              setIsAuth(true);
              setData(data);
          })
          .catch(error => console.log(error))  
    }

    //Получение списка моек (массив с ID)
    getWashesList = async (setIsAuth) => {
      let res = await this.getResource(`https://atod.online/data.php?what=wash`)
          .then(response => {
              if (response.ok) {
                  return response.json()
                } else if (response.status === 401) {
                    localStorage.removeItem("jwt");
                    setIsAuth(false);
                    console.log(response);
                    throw new Error(`Ошибка ${response.status} : ${response.statusText}`)
                } else {
                    console.log(response);
                    throw new Error(`Ошибка ${response.status} : ${response.statusText}`)
                }
          })
          .catch(error => console.log(error))  

          return res;
    }

    
}

    

export default DataBaseService;
import * as React from 'react';
import { styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ThemeProvider }  from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';

import HomeIcon from '@mui/icons-material/Home';
import BarChartIcon from '@mui/icons-material/BarChart';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble';
import ConstructionIcon from '@mui/icons-material/Construction';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';

import { grey, teal, cyan, purple, orange, lightBlue} from '@mui/material/colors';

import SegoeUI from '../fonts/SegoeUIVF.ttf'

import TodayStatistic from './TodayStatistic';
import PostInfo from './PostsInfo';
import MainPage from './pages/MainPage';
import Statistics from './pages/Statistics';
import Settings from './pages/Settings';
import Profile from './pages/Profile';
import Incasations from './pages/Incasations';
import Documentation from './pages/Documentation';
import Control from './pages/Control';
import ClientsCards from './pages/ClientsCards';
import CardReplenishment from './pages/CardReplenishment';

import { BrowserRouter as Router, Route, Routes, Link, Outlet, Navigate } from 'react-router-dom';


const drawerWidth = 240;

const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          // palette values for light mode
          primary: grey,
          divider: grey[700],
          secondary: lightBlue,
          background: {
            gradient : 'linear-gradient(150deg, rgba(164, 214, 255, 1) 0%, rgba(220, 245, 255, 1) 26%, rgba(217, 245, 255, 1) 99%)',
            opacity: 'rgba(34, 9, 63, 0.0)',
            opacityCard: 'rgba(255,255,255,0.3)',
            opacitySecondary: 'rgba(220, 245, 255, 1)'
          },
          info: lightBlue,
          
        }
      : {
          // palette values for dark mode
          primary: grey,
          secondary: orange,
          divider: orange[800],
          background: {
            default: purple[900],
            paper: purple[700],
            opacity: 'rgba(34, 9, 63, 0.0)',
            opacityCard: 'rgba(0,0,0,0.2)',
            opacitySecondary: 'rgba(255,255,255,0.1)',
            gradient: 'linear-gradient(150deg, rgba(60, 10, 96, 1) 13%, rgba(44, 12, 70, 1) 75%, rgba(113, 53, 14, 1) 99%)',
          },
          info: teal,
          text: {
            primary: '#fff',
          },
        }),
  },
});




const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export default function PersistentDrawerLeft() {
  const [open, setOpen] = React.useState(window.innerWidth > 500 ? true : false);
  const [title, setTitle] = React.useState('Главная страница');
  const [mode, setMode] = React.useState('dark');
  const [isMobile, setIsMobile] = React.useState(window.innerWidth > 500 ? false : true)
  const [isAuth, setIsAuth] = React.useState(true);
  const theme = React.useMemo(() => {
    const color = getDesignTokens(mode);
    const text = {
      typography: {
        fontFamily: 'SegoeUI, Arial',
      },
      components: {
        MuiCssBaseline: {
          styleOverrides: `
            @font-face {
              font-family: 'SegoeUI';
              font-style: normal;
              font-display: swap;
              font-weight: 400;
              src: local('SegoeUI'), local('SegoeUI-Regular'), url(${SegoeUI}) format('ttf');
              unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
            },
            {
              font-family: 'SegoeUI';
              font-style: light;
              font-display: swap;
              font-weight: 300;
              src: local('SegoeUI'), local('SegoeUI-Regular'), url(${SegoeUI}) format('ttf');
              unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
            },
          `,
        },
      },
    }
     return createTheme({...color,...text})
  }, [mode]);
  

    

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  function getItem (title, icon, path) {
    return {
      title,
      icon,
      path
    }
  }

  const changePage = (title) => {
    setTitle(title);
    if (isMobile) {
      handleDrawerClose()
    }
  }

  const items = [
    getItem('Главная страница', <HomeIcon/>, '/'),
    getItem('Статистика', <BarChartIcon/>, '/statistics'),
    getItem('Журнал инкасаций', <AccessTimeIcon/>, '/incasations'),
    getItem('Карты клиентов', <CreditCardIcon/>, '/clients'),
    // getItem('Пополнение карты', <CurrencyRubleIcon/>, '/replenishment'),
    getItem('Контроль оборудования', <ConstructionIcon/>, '/control'),
    getItem('Профиль пользователя', <PersonIcon/>, '/profile'),
    getItem('Настройки', <SettingsIcon/>, '/settings'),
    getItem('Документация', <MenuBookIcon/>, '/documentation'),
  ]

  const logOut = () => {
    localStorage.removeItem('jwt');
    setIsAuth(false);
  }

  return (
    // <Router>
      <ThemeProvider theme={theme}>
        <Box sx={{ display: 'flex', background:theme.palette.background.gradient, backgroundAttachment:'fixed'}}>
          <CssBaseline />
          <AppBar position="fixed" open={open} sx={{boxShadow: 'none', backgroundColor: theme.palette.background.opacity,
                   '@media print': {
                    display: 'none'
                  } }}  >
            <Toolbar 
              sx={{
                backgroundColor: theme.palette.background.opacity,
                }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ mr: 2, backgroundColor: theme.palette.secondary.dark, borderRadius: '0' , color: 'black', ...(open && { display: 'none' }) }}
              >
                <MenuIcon />
              </IconButton>
              <Typography sx={{flexGrow: '1'}} variant="h6" noWrap component="div">
                {title}
              </Typography>
              {/* <IconButton onClick={() => setMode(mode === 'dark' ? 'light' : 'dark')}>
                <WbSunnyIcon/>
              </IconButton> */}
              <IconButton onClick={() => logOut()}>
                <LogoutRoundedIcon/>
              </IconButton>
              
              
            </Toolbar>
          </AppBar>
          <Drawer 
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
                boxShadow: 'none',
                border: 'none',
                backgroundColor: theme.palette.background.opacity,
                '@media print': {
                  display: 'none'
                }
              },
            }}
            variant="persistent"
            anchor="left"
            open={open}
            
            
          >
            <DrawerHeader sx={{backgroundColor: 'rgba(255, 255, 255, 0.1)'}}>
              Atod Мониторинг
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </DrawerHeader>
            <List>
              {items.map(({title, icon, path}, index) => (
                <ListItem key={title} disablePadding >
                  <ListItemButton key={title} sx={{borderRadius: 15}}  onClick={() => changePage(title)} component={Link} to={path} >
                    <ListItemIcon key={title} sx={{color:theme.palette.info.light}}>
                      {icon}
                    </ListItemIcon>
                    <ListItemText primary={title} primaryTypographyProps={{fontSize:'14px'}}/>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Drawer>
          <Main open={open} sx={{overflow: (isMobile && open) ? 'hidden' : null, minHeight:'100vh', paddingLeft:0, pr:0}} >
            <DrawerHeader />
            {!isAuth && <Navigate to={'/login'}/>}
            <Routes>
            <Route path='/' element={<MainPage/>}/>
            </Routes>
            {/* <Routes> */}
              {/* <Route path='/dashboard/main' element={<MainPage/>}/>
              <Route path='/profile' element={<Profile/>}/>
              <Route path='/statistics' element={<Statistics/>}/>
              <Route path='/settings' element={<Settings/>}/>
              <Route path='/incasations' element={<Incasations/>}/>
              <Route path='/documentation' element={<Documentation/>}/>
              <Route path='/control' element={<Control/>}/>
              <Route path='/clients' element={<ClientsCards/>}/>
              <Route path='/replenishment' element={<CardReplenishment/>}/> */}
            {/* </Routes> */}
            <Outlet/>
            {/* <TodayStatistic></TodayStatistic>
            <Divider sx={{mt: 5, mb: 5}}/>
            <PostInfo/> */}
            
          </Main>
        </Box>
      </ThemeProvider>
    // </Router>

  );
}
import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Collapse, Grid, Typography,  Box,  } from "@mui/material";

import { ResponsiveContainer, YAxis } from "recharts";
import { LineChart, Line, XAxis,  Tooltip, Legend } from 'recharts';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

import DataBaseService from "../services/dataBaseService";

const dataBaseService = new DataBaseService();



var updateLocale = require('dayjs/plugin/updateLocale')
dayjs.extend(updateLocale)

dayjs.updateLocale('en', {
    weekdays: [
      "Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"
    ]
  })

const LineStatistic =  (props) => {
    const [period, setPeriod] = useState('today');
    const [startDay, setStartDay] = useState(dayjs());
    const [endDay, setEndDay] = useState(dayjs());
    const [isAuth, setIsAuth] = useState(true);
    const [data, setData] = useState([]);
    const [visability, setVisability] = useState({cash: true, term: true, coins: true, sum: true,})
    const wash = props.wash;
    const open = period === 'manual'? true : false;

    const createDigitData = (data) => {
        return data.map(item => {
            return {
                chdate: period === 'week' ? dayjs(item.Chdate).format('dddd') : startDay.format('YYYY-MM-DD') === endDay.format('YYYY-MM-DD') ? dayjs(item.checkdata).format('HH:mm:ss') : dayjs(item.Chdate).format('DD.MM'),
                coins: +item.coins,
                cash: +item.cash,
                term: +item.term,
                sum: +item.coins + +item.cash + +item.term
            }
        })
    }

    useEffect(() => {
        if (startDay && endDay && period !== 'today' && startDay.format('YYYY-MM-DD') !== endDay.format('YYYY-MM-DD')  ) {
            dataBaseService.getResource(`https://atod.online/statistics.php?wht=stat&cw=${wash}&with=${startDay.format('YYYY-MM-DD')} 00:00:00&by=${endDay.format('YYYY-MM-DD')} 23:59:59`)
            .then(response => {
                if (response.ok) {
                    return response.json()
                    
                } else if (response.status === 401) {
                    localStorage.removeItem("jwt");
                    setIsAuth(false)
                    console.log(response)
                    throw new Error(`Ошибка ${response.status} : ${response.statusText}`)
                } else {
                    console.log(response)
                    throw new Error(`Ошибка ${response.status} : ${response.statusText}`)
                }
            })
            .then(data => setData(() => createDigitData(data)))
            .catch(error => console.log(error))
        } else if ( period === 'today' || startDay.format('YYYY-MM-DD') === endDay.format('YYYY-MM-DD') ) {
            dataBaseService.getResource(`https://atod.online/statistics.php?wht=statDay&cw=${wash}&with=${dayjs().format('YYYY-MM-DD')} 00:00:00&by=${dayjs().format('YYYY-MM-DD')} 23:59:59`)
            .then(response => {
                if (response.ok) {
                    console.log(response)
                    return response.json()
                    
                } else if (response.status === 401) {
                    localStorage.removeItem("jwt");
                    setIsAuth(false)
                    console.log(response)
                    throw new Error(`Ошибка ${response.status} : ${response.statusText}`)
                } else {
                    console.log(response)
                    throw new Error(`Ошибка ${response.status} : ${response.statusText}`)
                }
            })
            .then(data => setData(() => createDigitData(data)))
            .catch(error => console.log(error))
        }
    },[startDay, endDay, wash])

    const test = (o) => {
        const {dataKey} = o;
        setVisability({...visability, [dataKey]:!visability[dataKey]})
    }

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
          return (
            <Box style={{backgroundColor:'rgba(255,255,255,0.1)', textAlign:'center', padding:'2px', borderRadius:'8px'}}>
              <Typography component={'span'} className="label">{`${label}`}</Typography>
              {payload.map((payload, index) => {
                return <Typography key={index } component={'div'} style={{color:payload.color}}>{`${payload.name}: ${payload.value}`}</Typography>
              })}
            </Box>
          );
        }
      
        return null;
      };

    
    const changePeriod = (period) => {
        setPeriod(period)

        if (period === 'week' || period === 'month') {
            setStartDay(dayjs().subtract(1, period))
            setEndDay(dayjs())
        } else if (period === 'today') {
            setStartDay(dayjs())
            setEndDay(dayjs())
        }
    } 
 
    return (
        <>
            <ButtonGroup variant="outlined" aria-label="outlined primary button group">
                <Button variant={period == 'today' ? "contained": null} onClick={() => changePeriod('today')}>Сегодня</Button>
                <Button variant={period == 'week' ? "contained": null} onClick={() => changePeriod('week')}>Неделя</Button>
                <Button variant={period == 'month' ? "contained": null} onClick={() => changePeriod('month')}>Месяц</Button>
                <Button variant={period == 'manual' ? "contained": null} onClick={() => changePeriod('manual')}>Период</Button>
            </ButtonGroup>
            
            <Collapse in={open}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
                    <Grid container spacing={2} mb={2}  justifyContent={'start'} sx={{pt:2}}>
                        <Grid item ml={2} xs={5} md={2}>   
                            <DatePicker
                                format="DD.MM.YYYY"
                                label="Начало периода"
                                value={startDay}
                                maxDate={endDay}
                                slotProps={{ textField: { size: 'small' } }}
                                onChange={(newValue) => setStartDay(newValue)}
                            />
                        </Grid> 
                        <Grid item ml={2} xs={5} md={2}>
                            <DatePicker
                                format="DD.MM.YYYY"
                                label="Конец периода"
                                value={endDay}
                                slotProps={{ textField: { size: 'small' } }}
                                onChange={(newValue) => setEndDay(newValue)}
                            />
                        </Grid>
                        <Grid item alignSelf={'center'}><Typography variant="span" >{(startDay > endDay) ? 'не правильный период' : null}</Typography></Grid>
                    </Grid>
                </LocalizationProvider>
            </Collapse>
            {(data && data.length > 0) ? <Box mt={2}>
            <ResponsiveContainer width='98%' height={300}>
                <LineChart width={730} height='20wh'  data={data}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                    {/* <CartesianGrid strokeDasharray="3 3" /> */}
                    <XAxis dataKey="chdate" reversed />
                    <YAxis domain={[0, 'dataMax ']} />
                    <Tooltip content={<CustomTooltip/>} />
                    <Legend onClick={test}/>
                    <Line type="monotone" dataKey="cash" stroke="#FF6B0E" dot={false} hide={!visability.cash} name="купюры" strokeWidth={2}/>
                    <Line type="monotone" dataKey="coins" stroke="#0EFF5E" dot={false} hide={!visability.coins} name="монеты" strokeWidth={2} />
                    <Line type="monotone" dataKey="term" stroke="#15A5FA" dot={false} hide={!visability.term} name="терминал" strokeWidth={2}/>
                    <Line type="monotone" dataKey="sum" stroke="#FA15FA" dot={false} hide={!visability.sum} name="сумма" strokeWidth={2}/>
                </LineChart>
            </ResponsiveContainer>
            </Box> : 
            
            <Box sx={{m:2}}>
                <Typography>За данный период данные не найдены</Typography>
            </Box>} 
        </>
    )
}

export default LineStatistic;
import { CardContent, Grid, Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";


const Cars = (props) => {
    const [carsValue, setCarsValue] = useState('0')
    const {startDay, endDay, wash} = props;

    useEffect(() => {
        fetch(`https://atod.online/data.php?what=cars&cr=${wash}&since=${startDay.format('YYYY-MM-DD')}&until=${endDay.format('YYYY-MM-DD')}`,{
            method: "GET",
            headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('jwt')}`
            }
        })
        .then(response => response.json())
        .then(value => setCarsValue(value[0].washedCars))
    })
    
    return (
        <CardContent>
            <Grid container justifyContent={'space-between'}>
                <Grid item>
                <Typography sx={{ fontSize: 14, fontWeight: 'bold' }} color="text.secondary" gutterBottom>
                Колличество машин
                </Typography>
                </Grid>
            </Grid>
            <Typography sx={{fontSize: 24, fontWeight: 'bold'}} color='text.secondary' >
                 {carsValue} 
            </Typography>
        </CardContent>
    )
}

export default Cars;
import { Box, Typography, Grid, Icon, Stack } from "@mui/material"
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import DataBaseService from "../services/dataBaseService";

const dataBaseService = new DataBaseService(); 

const isMobile = window.innerWidth > 500 ? false : true

const StatisticsCard = (props) => {
    const [data, setData] = useState([]);
    const [cardSum, setCardSum] = useState([])
    const wash = props.wash;

    useEffect(() => {
        dataBaseService.getResource(`https://atod.online/statistics.php?wht=stat&cw=${wash}&with=${dayjs().subtract(2,'days').format('YYYY-MM-DD')} 00:00:00&by=${dayjs().subtract(2,'days').format('YYYY-MM-DD')} 23:59:59`)
        .then(response => response.json())
        .then(data => (setData(data[0])))
    },[wash])

    useEffect(() => {
        dataBaseService.getResource(`https://atod.online/statistics.php?wht=smcd&cw=${wash}`)
        .then(response => response.json())
        .then(data => (setCardSum(data[0].AllCardBalans)))
    },[wash])

    return (
        (data && data.length > 0) ? <Stack direction={isMobile ? 'column' : 'row'} spacing={2} marginLeft={'2%'} sx={{'@media print': {
            display: 'none'
          }}}>
            <Box width={isMobile ? '96%' : '360px'} height={'180px'} borderRadius={4} sx={{background: 'rgba(0,0,0,0.4)'}} p={2}>
                <Grid container>
                <Grid item xs={10}>  
                <Typography  fontSize='xl' fontWeight={'500'} variant="h6" mt={0} >Поступления</Typography>
                <Typography  fontSize='16px' fontWeight={'500'} color={'text.secondary'} gutterBottom>текущий день</Typography>
                </Grid>
                <Grid item mt={1} >
                    <Icon sx={{width:'48px', height:'48px'}}  children={<AccountBalanceWalletIcon sx={{width:'48px', height:'48px'}} />}></Icon>
                </Grid>  
                </Grid>
                <Box mt={2} width={'100%'} height={'75px'} borderRadius={2} sx={{background: 'rgba(255,255,255,0.1)'}}> 
                    <Grid container p={1} justifyContent={'space-between'}>
                        <Grid  item >
                            <Typography fontSize={'12'} fontWeight={'500'} color={'text.secondary'} >Купюры</Typography>
                            <Typography fontSize={'12'} >{data.cash} ₽</Typography>
                        </Grid>

                        <Grid item >
                            <Typography fontSize={'12'} color={'text.secondary'}>Монеты</Typography>
                            <Typography fontSize={'12'}>{data.coins} ₽</Typography>
                        </Grid>

                        <Grid item >
                            <Typography fontSize={'12'} color={'text.secondary'}>Терминал</Typography>
                            <Typography fontSize={'12'}>{data.term} ₽</Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            <Box width={isMobile ? '96%' : '360px'} height={'180px'} borderRadius={4} sx={{background: 'rgba(0,0,0,0.4)'}} p={2}>
                <Grid container>
                <Grid item xs={10}>  
                <Typography  fontSize='xl' fontWeight={'500'} variant="h6" mt={0} >Общая сумма</Typography>
                <Typography  fontSize='16px' fontWeight={'500'} color={'text.secondary'} gutterBottom>на картах</Typography>
                </Grid>
                <Grid item mt={1} >
                    <Icon sx={{width:'48px', height:'48px'}}  children={<CreditCardIcon sx={{width:'48px', height:'48px'}} />}></Icon>
                </Grid>  
                </Grid>
                <Box mt={2} width={'100%'} height={'75px'} borderRadius={2} sx={{background: 'rgba(255,255,255,0.1)'}}> 
                    <Typography fontSize={'40px'} align="center">{cardSum} ₽</Typography>
                </Box>
            </Box>
        </Stack> : null
    )
}

export default StatisticsCard;
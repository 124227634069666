import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DataBaseService from '../services/dataBaseService';
import {CircularProgress, LinearProgress, Skeleton} from '@mui/material';
import { Button, Divider } from '@mui/material';
import {Grid} from '@mui/material';

import BorderColorIcon from '@mui/icons-material/BorderColor';
import EditCardInfo from './EditCardInfo';
import Replenishment from './Replenichment';
import BlockingCard from './BlockingCard';

const dataBaseService = new DataBaseService;


    
    
    

    

    


    

    function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const [history, setHistory] = React.useState([])
    const [isScrolledToBottomSecondary, setIsScrolledToBottomSecondary] = React.useState(false)
    const [offsetSecondary, setOffsetSecondary] = React.useState(0)
    const secondaryTableRef = React.useRef()

    const openInfo = async() => {
        if (open === false) {
            await dataBaseService.getResource(`https://atod.online/cards.php?wht=crdlg&cud=${row.cardUid}&whs=${props.wash}&with=0&kol=20`)
            .then(response => response.json())
            .then(data => {
                setHistory(data)
            })

            setOpen(!open) 
        } else {
            secondaryTableRef.current.removeEventListener('scroll',throttle);
            setOffsetSecondary(0);
            setOpen(!open)
        }
           
    }

    

    const handleScrollSecondary = () => {
        if (secondaryTableRef && secondaryTableRef.current.clientHeight > 0) {
            const elementHeight = +secondaryTableRef.current.scrollHeight;
            const windowHeight = +secondaryTableRef.current.clientHeight;
            const scrolledHeight = +secondaryTableRef.current.scrollTop;


            const isScrolledToBottom = scrolledHeight >= elementHeight - windowHeight -5;

            setIsScrolledToBottomSecondary(isScrolledToBottom);
        }
        
    }

    function throttle(callee, timeout) {
        let timer = null
        
        return function perform(...args) {
            if (timer) return
        
            timer = setTimeout(() => {
            callee(...args)
        
            clearTimeout(timer)
            timer = null
            }, timeout)
        }
    } 
    
    const checkProgramm = (programmNum) => {
        switch (+programmNum) {
            case 1:
                return 'Вода';
            case 2:
                return 'Пена';
            case 3:
                return 'Воск';
            case 4:
                return 'Осмос';
            case 5:
                return 'Пылесос';
            case 6:
                return 'Антимошка';
            case 7:
                return 'Горячая вода';
            case 8:
                return 'Тефлон';
            case 9:
                return 'Стоп';
            case 10:
                return 'Тотал стоп';
            case 11:
                return 'Включен сервис ключ';  
            case 12:
                return 'Выключен сервис ключ';
            case 13:
                return 'Вставлена карта';
            case 14:
                return 'Пополнение поста наличными';
            case 15:
                return 'Пополнение поста через Яндекс';
            case 16:            
                return 'Пополнение поста с сайта';
            case 17:
                return 'Зачисление сдачи на карту';
            case 18:        
                return 'Конец мойки';
            case 19:
                return 'Пост открыт'; 
            case 20:
                return 'Пост закрыт';  
            case 21:
                return 'Печать чека';
            case 22:
                return 'Начало платной паузы';
            case 23:
                return 'Конец бесплатной паузы';
            case 24:
                return 'Пополнение карты'; 
            case 25:
                return 'Пополнение карты с сайта';
            case 26:
                return 'Пинг сервера';                              
            case 27:
                return 'Пост закрыт';
            case 28:
                return 'Обновление настроек';
            case 29:
                return 'Перезагрузка поста с сервера';
            case 30:
                return 'Неисправность кнопок';
            case 31:
                return 'Неисправность внешней матрицы';
            case 32:
                return 'Неисправность внутреннего экрана';
            case 33:
                return 'Проблема с питанием платы';
            case 34:
                return 'Ошибка частотного преобразователя';
            case 35:                                
                return 'Ошибка внешнего блока релле';
            case 36:
                return 'Перезагрузка';
            case 37:
                return 'Ошибка по wi-fi';
            case 38:
                return 'Ошибка подключения к серверу';
            case 39:
                return 'Повторное использование активной карты'; 
            case 40:
                return 'Попытка использования заблокированной карты'; 
            case 41:
                return 'Вызов оператора на пост'; 
            case 42:
                return 'Выдан остаток средств на карте';
            case 43:
                return 'Ошибка nfc'; 
            case 44:
                return 'Ошибка дисплея';
            case 44:
                return 'Включение платы';                              
            default: 
                return 'Ошибка';                 
        }
    }
      
    
    
    React.useEffect(()=>{
        if (secondaryTableRef.current) {
            secondaryTableRef.current.addEventListener('scroll',throttle(handleScrollSecondary, 350))
            console.log(secondaryTableRef.current)
            // return () =>{
            //     secondaryTableRef.current.removeEventListener('scroll',throttle(handleScrollSecondary, 350))
            // }
        }
    },[open]) 
    
    React.useEffect(() => {
        
        if (isScrolledToBottomSecondary) {
            dataBaseService.getResource(`https://atod.online/cards.php?wht=crdlg&cud=${row.cardUid}&whs=${props.wash}&with=${offsetSecondary+20}&kol=20`)
            .then(response=> response.json())
            .then(data => {
                const newHistory = [...history, ...data];
                setHistory(newHistory)
            })

            setOffsetSecondary(offsetSecondary + 20)
            setIsScrolledToBottomSecondary(false)
        }
    }, [isScrolledToBottomSecondary])

    
    


    return (
        <React.Fragment  >
        <TableRow sx={{ '& > *': { borderBottom: 'unset' }, backgroundColor:row.cardIsActive == '1'? 'rgba(0,255,0,0.3)':null }}>
            <TableCell>
            <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => openInfo()}
            >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            </TableCell>
            <TableCell component="th" scope="row">
                <Grid container >
                    <Grid item>
                        <EditCardInfo cardUid={row.cardUid} wash={props.wash}/>
                    </Grid>
                    <Grid item>
                        {row.cardUid} {row.cardIsActive === '1'? '(активна)' : null}
                    </Grid>
                </Grid>
            
            </TableCell>
            <TableCell align="center">
                <Grid container>
                    <Grid item>
                        <Replenishment info={row} wash={props.wash} isActive={row.cardIsActive} cardBalans={row.cardBalans}/>
                    </Grid>
                    
                    
                </Grid>
            </TableCell>
            <TableCell align="left">{row.personalDiscont}</TableCell>
            <TableCell align="left">{row.firmDiscont}</TableCell>
            <TableCell align="right">{row.lastUsedCard}</TableCell>
            <TableCell align="right"><BlockingCard status={row.blockCard} isActive={row.cardIsActive} wash={props.wash} cardUid={row.cardUid}/></TableCell>
        </TableRow>
        <TableRow sx={{backgroundColor:'rgba(255,255,255,0.0)'}}   >
            
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1,  }}>
                <Typography variant="h6" gutterBottom component="div">
                    История
                </Typography>
                <Box sx={{maxHeight:'340px', overflow:'auto', backgroundColor:'rgba(255,255,255,0.1)' }} ref={secondaryTableRef} >
                <TableContainer >  
                <Table size="small" aria-label="purchases"  >
                    <TableHead>
                    <TableRow>
                        <TableCell>Баланс</TableCell>
                        <TableCell>Программа</TableCell>
                        <TableCell align="right">Пост</TableCell>
                        <TableCell align="right">Дата</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {history.map((historyRow, index) => (
                        <TableRow key={index}>
                        <TableCell component="th" scope="row">{historyRow.cardBalans} ₽</TableCell>
                        <TableCell >{checkProgramm(historyRow.statCode)}</TableCell>
                        <TableCell align="right">{historyRow.postNum}</TableCell>
                        <TableCell align="right">{historyRow.loggingDate}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
                </TableContainer>  
                </Box>
                </Box>
            </Collapse>
            </TableCell>
        </TableRow>
        </React.Fragment>
    );
    }

    // Row.propTypes = {
    // row: PropTypes.shape({
    //     calories: PropTypes.number.isRequired,
    //     carbs: PropTypes.number.isRequired,
    //     fat: PropTypes.number.isRequired,
    //     history: PropTypes.arrayOf(
    //     PropTypes.shape({
    //         amount: PropTypes.number.isRequired,
    //         customerId: PropTypes.string.isRequired,
    //         date: PropTypes.string.isRequired,
    //     }),
    //     ).isRequired,
    //     name: PropTypes.string.isRequired,
    //     price: PropTypes.number.isRequired,
    //     protein: PropTypes.number.isRequired,
    // }).isRequired,
    // };



    // function CardsTable(props) {
    //     const [isScrolledToBottom, setIsScrolledToBottom] = React.useState(false)
    //     const [stopScroll, setStopScroll] = React.useState(false)
    //     const [rows1, setRows1] = React.useState([])
    //     const [offset, setOffset] = React.useState(0)
    //     const mainTableRef = React.useRef()
    //     const [loading, setLoading] = React.useState(false)

    //     React.useEffect(() => {
    //         setOffset(0);
    //         setStopScroll(false)
    //         setIsScrolledToBottom(false)
    //     }, [props.wash])

    //     React.useEffect(() => {
    //         dataBaseService.getResource(`https://atod.online/cards.php?wht=cli&whs=${props.wash}&with=0&kol=20`)
    //             .then(response=> response.json())
    //             .then(data => {
    //                 setRows1(data);
    //                 setOffset(0);
    //             })
    //     },[props.wash])

    //     const handleScroll = () => {
    //         if (!stopScroll) {
    //             const elementHeight = +mainTableRef.current.scrollHeight;
    //             const windowHeight = +mainTableRef.current.clientHeight;
    //             const scrolledHeight = +mainTableRef.current.scrollTop;
        
        
    //             const isScrolledToBottom = scrolledHeight >= elementHeight - windowHeight -50;
    //             // mainTableRef.current.removeEventListener('scroll',throttle)
    //             setIsScrolledToBottom(isScrolledToBottom);
                
    //         } 
            
    //     }

    //     function throttle(callee, timeout) {
    //         let timer = null
            
    //         return function perform(...args) {
    //             if (timer) return
            
    //             timer = setTimeout(() => {
    //             callee(...args)
            
    //             clearTimeout(timer)
    //             timer = null
    //             }, timeout)
    //         }
    //         }    

    //     React.useEffect(()=>{
    //         if (mainTableRef.current && !stopScroll) {
    //             mainTableRef.current.addEventListener('scroll',throttle(handleScroll, 350))
    //             // console.log(mainTableRef.current)
    //             // return () =>{
    //             //     mainTableRef.current.removeEventListener('scroll',throttle)
    //             }
    //         // } else {
    //         //     mainTableRef.current.removeEventListener('scroll',throttle)
    //         // }
    //     },[props.wash]) 

    //     React.useEffect(() => {
        
    //         if (isScrolledToBottom && !stopScroll) {
    //             // setLoading(true)
    //             setOffset(offset + 20)
    //             dataBaseService.getResource(`https://atod.online/cards.php?wht=cli&whs=${props.wash}&with=${offset}&kol=20`)
    //             .then(response=> response.json())
    //             .then(data => {
    //                 if (data.length < 1) {
    //                     // mainTableRef.current.removeEventListener('scroll', throttle);
    //                     setStopScroll(true);
    //                     setIsScrolledToBottom(false)
    //                    } else {
    //                         // const newRows = [...rows1, ...data];
    //                         setRows1((prewRows) => [...prewRows, ...data]);
    //                         // console.log(data.length)
    //                         // setLoading(false)
    //                         setIsScrolledToBottom(false)
    //                     }
                    
                    
    //             })
    
                
                
                
    //         }
    //     }, [isScrolledToBottom])

    // return (
    //     <>
    //     <TableContainer component={Paper} sx={{maxHeight:'80vh', backgroundColor:'rgba(255,255,255,0.0)'}} ref={mainTableRef} >
    //     <Table stickyHeader={true} aria-label="collapsible table" size='small' >
    //         <TableHead >
    //         <TableRow>
    //             <TableCell />
    //             <TableCell>UID Карты</TableCell>
    //             <TableCell align="left">Баланс</TableCell>
    //             <TableCell align="left">Персональный дисконт</TableCell>
    //             <TableCell align="left">Корпоративный дисконт</TableCell>
    //             <TableCell align="right">Дата последнего использования</TableCell>
    //             <TableCell align="right">Статус</TableCell>
    //         </TableRow>
    //         </TableHead>
    //         <TableBody  >
    //         {rows1.map((row, index) => (
    //             <Row key={index} row={row} wash={props.wash} />
    //         ))}
    //         </TableBody>
            
    //     </Table>
    //     </TableContainer>
    //     {/* { loading && 
    //         <>  
    //             <LinearProgress color='secondary' sx={{mt:1}}/>
    //         </>} */}

    //     </>
    // );
    // }

    function CardsTable(props) {
        const [rows1, setRows1] = React.useState([]);
        const [offset, setOffset] = React.useState(0);
        const [isScrolledToBottom, setIsScrolledToBottom] = React.useState(false);
        const [stopScroll, setStopScroll] = React.useState(false);
        const [loading, setLoading] = React.useState(false);
        const mainTableRef = React.useRef(null);
      
        const handleScroll = () => {
          if (!stopScroll) {
            const elementHeight = mainTableRef.current.scrollHeight;
            const windowHeight = mainTableRef.current.clientHeight;
            const scrolledHeight = mainTableRef.current.scrollTop;
            const isScrolledToBottom = scrolledHeight >= elementHeight - windowHeight - 250;
      
            setIsScrolledToBottom(isScrolledToBottom);
          }
        };

        function throttle(callee, timeout) {
            let timer = null
            
            return function perform(...args) {
                if (timer) return
            
                timer = setTimeout(() => {
                callee(...args)
            
                clearTimeout(timer)
                timer = null
                }, timeout)
            }
        }    
      
        const fetchData = (newOffset) => {
          setLoading(true);
          dataBaseService
            .getResource(`https://atod.online/cards.php?wht=cli&whs=${props.wash}&with=${newOffset}&kol=20`)
            .then((response) => response.json())
            .then((data) => {
              if (data.length < 1) {
                setStopScroll(true);
              } else {
                setRows1((prevRows) => [...prevRows, ...data]);
                setOffset(newOffset);
              }
              setLoading(false);
            });
        };
      
        React.useEffect(() => {
          if (props.wash !== undefined) {
            setOffset(0);
            setStopScroll(false);
            setIsScrolledToBottom(false);
            setRows1([]);
      
            fetchData(0);
          }
        }, [props.wash]);
      
        React.useEffect(() => {
          if (mainTableRef.current && !stopScroll) {
            mainTableRef.current.addEventListener('scroll', throttle(handleScroll, 100));
          }
      
          return () => {
            if (mainTableRef.current) {
              mainTableRef.current.removeEventListener('scroll', throttle);
            }
          };
        }, [props.wash, stopScroll]);
      
        React.useEffect(() => {
          if (isScrolledToBottom && !stopScroll) {
            fetchData(offset + 20);
          }
        }, [isScrolledToBottom, stopScroll]);
      
        return (
          <>
            <TableContainer component={Paper} sx={{ maxHeight: '80vh', backgroundColor: 'rgba(255, 255, 255, 0.0)' }} ref={mainTableRef}>
              <Table stickyHeader aria-label="collapsible table" size="small">
              {rows1.map((row, index) => (
                <Row key={index} row={row} wash={props.wash} />
            ))}
              </Table>
            </TableContainer>
      
            {loading && <LinearProgress color="secondary" sx={{ mt: 1 }} />}
          </>
        );
      }



export default CardsTable
import React from 'react'
import { useLocation, Navigate } from 'react-router-dom'

const RequireAuth = (props) => {
    const auth = props.auth;
    const location = useLocation()

    if (!auth) {
        return <Navigate to="/login" state={{from: location}} />
    }

    return props.children
}

export default RequireAuth
import { CardContent, Grid, Box, Typography, Button, Menu, MenuItem } from "@mui/material";

import { useEffect, useState } from "react";


const AverageBill = (props) => {
    const [billValue, setBillValue] = useState('')
    const {startDay, endDay, wash} = props;
    const [billType, setBillType] = useState('Общий')
    const [anchorEl, setAnchorEl] = useState(null);
    
    const open = Boolean(anchorEl);

    const showData = () => {
        const {avgCoins, avgCash, avgTerm} = billValue[0]
        switch (billType) {
            case 'Общий':
                return Math.round((+avgCash + +avgCoins + +avgTerm) / 3);
            case 'Купюры':
                return Math.round(+avgCash);
            case 'Терминал':
                return Math.round(+avgTerm);
            case 'Монеты':
                return Math.round(+avgCoins);            
        }
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const changeType = (type) => {
        setBillType(type);
        handleClose()
    }

    // useEffect(() => {
    //     fetch(`https://atod.online/data.php?what=check&cw=${wash}&since=${startDay.format('YYYY-MM-DD')}&until=${endDay.format('YYYY-MM-DD')}`,{
    //         method: "GET",
    //         headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${localStorage.getItem('jwt')}`
    //         }
    //     })
    //     .then(response => response.json())
    //     .then(value => setBillValue(value))
    // },[])
    
    useEffect(() => {
        fetch(`https://atod.online/data.php?what=check&cw=${wash}&since=${startDay.format('YYYY-MM-DD')}&until=${endDay.format('YYYY-MM-DD')}`,{
            method: "GET",
            headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem('jwt')}`
            }
        })
        .then(response => response.json())
        .then(value => setBillValue(value))
    },[wash])
    
    return (
        <CardContent>
            <Grid container justifyContent={'space-between'}>
                <Grid item>
                    <Typography sx={{ fontSize: 14, fontWeight: 'bold' }} color="text.secondary" gutterBottom>
                    Среднее пополнение
                    </Typography>
                </Grid>
                <Grid item>
                    <Button
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        sx={{mt:'-7px', borderRadius:'20px'}}
                        onClick={handleClick}
                    >
                        {billType}
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                        'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={() => changeType('Общий')}>Общий</MenuItem>
                        <MenuItem onClick={() => changeType('Купюры')}>Купюры</MenuItem>
                        <MenuItem onClick={() => changeType('Терминал')}>Терминал</MenuItem>
                        <MenuItem onClick={() => changeType('Монеты')}>Монеты</MenuItem>
                    </Menu>
                </Grid>
            </Grid>
            <Typography sx={{fontSize: 24, fontWeight: 'bold'}}  color='text.secondary' >
                ₽ {billValue && showData()} 
            </Typography>
        </CardContent>
    )
}

export default AverageBill;
import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { LineChart } from '@mui/x-charts';
import LineStatistic from '../LineStatistic';
import StatisticsCard from '../StatisticsCard';
import BillStatistics from '../BillStatistics';
import TestChart from '../TestChart';
import DataBaseService from '../../services/dataBaseService';
import { Navigate } from 'react-router-dom';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const dataBaseService = new DataBaseService();



function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 2 }}>
          <Typography variant='div'>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Statistics() {
  const [value, setValue] = React.useState(0);
  const[washesList, setWashesList] = React.useState();
    const[isAuth, setIsAuth] = React.useState(true);
    const[visibleWash, setVisibleWash] = React.useState();
    
    React.useEffect (() => {
        dataBaseService.getWashesList(setIsAuth)      // При изменении состояния отвечающего за добавление новой мойки обновляем список моек
        .then(data => setWashesList(data))
    },[])

    React.useEffect(() => {
        if (!sessionStorage.getItem('wash') && washesList) {
            setVisibleWash(washesList[0].id)
        } else if (sessionStorage.getItem('wash') && washesList) {
            setVisibleWash(sessionStorage.getItem('wash'))
        }
    },[washesList])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>  
        {!isAuth ? <Navigate to={'/login'}/> : null}
          {visibleWash && 
                  <FormControl sx={{ m: 1, minWidth: 120, '@media print': {
                    display: 'none'
                  } }} size="small">
                      <InputLabel id="select-wash">Мойка</InputLabel>
                      <Select
                          labelId="select-wash"
                          id="demo-select-small"
                          value={visibleWash}
                          label='Мойка'
                          onChange={(e) => setVisibleWash(e.target.value)}
                      >
                          <MenuItem value={washesList[0].id}>
                          <em>{washesList[0].street} {washesList[0].building}</em>
                          </MenuItem>
                          {
                              washesList.map((item, num) => {
                                  if (num === 0 ) {
                                      return null
                                  }
                                  return <MenuItem key={item.id} value={item.id}>{`${item.street} ${item.building}`}</MenuItem>
                              })
                          }
                      </Select>
                  </FormControl>
            }
        {visibleWash && <StatisticsCard wash={visibleWash}/>}
        
        <Box sx={{ width: '100%', '@media print': {
                    display: 'none'
                  }}}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Поступления" {...a11yProps(0)} />
            <Tab disabled label="Машины" {...a11yProps(1)} />
            <Tab disabled label="Прочее" {...a11yProps(2)} />
            </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
        {visibleWash && <LineStatistic wash={visibleWash}/>}
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
            <TestChart/>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
            Item Three
        </CustomTabPanel>
        </Box>

        <BillStatistics wash={visibleWash}/>
    </>
  );
}
import CardsTable from "../CardsTable.js";
import CardsTableMobile from "../CardsTableMobile.js";
import DataBaseService from "../../services/dataBaseService";
import { useState, useEffect } from "react";
import { FormControl, InputLabel, Select, MenuItem, CssBaseline } from "@mui/material";
import { Navigate } from "react-router-dom";

const dataBaseService = new DataBaseService()

const isMobile = window.innerWidth > 500 ? false : true

const ClientsCard = () => {
    const[washesList, setWashesList] = useState();
    const[isAuth, setIsAuth] = useState(true);
    const[visibleWash, setVisibleWash] = useState();
    
    useEffect (() => {
        dataBaseService.getWashesList(setIsAuth)      // При изменении состояния отвечающего за добавление новой мойки обновляем список моек
        .then(data => setWashesList(data))
    },[])

    useEffect(() => {
        if (!sessionStorage.getItem('wash') && washesList) {
            setVisibleWash(washesList[0].id)
        } else if (sessionStorage.getItem('wash') && washesList) {
            setVisibleWash(sessionStorage.getItem('wash'))
        }
    },[washesList])

    console.log(washesList)

    return (
        <div> 
         {!isAuth ? <Navigate to={'/login'}/> : null}
         {visibleWash && 
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel id="select-wash">Мойка</InputLabel>
                    <Select
                        labelId="select-wash"
                        id="demo-select-small"
                        value={visibleWash}
                        label='Мойка'
                        onChange={(e) => setVisibleWash(e.target.value)}
                    >
                        <MenuItem value={washesList[0].id}>
                        <em>{washesList[0].street} {washesList[0].building}</em>
                        </MenuItem>
                        {
                            washesList.map((item, num) => {
                                if (num === 0 ) {
                                    return null
                                }
                                return <MenuItem key={item.id} value={item.id}>{`${item.street} ${item.building}`}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
            }
            

            {(visibleWash && isMobile) ? <CardsTableMobile wash={visibleWash}/> : null}
            {(visibleWash && !isMobile) ? <CardsTable wash={visibleWash}/> : null}              
        </div>
        
    )
}

export default ClientsCard;